html {
  font-size: 14px;
}

/* Handle close Navigation */
body.close-navigation #alert-wrapper,
body.close-navigation #actionpane,
body.close-navigation #functionbar {
  width: 100%;
}

body.close-navigation #navigation {
  margin-left: -$navigation-width;
}

/* End close Navigation */

.title {
  font-weight: bold;
  font-size: 16px;
}

.title-divider {
  border-bottom: 1px solid;
  border-color: $text-muted;
  padding-bottom: 5px;
}

.title-divider.disabled {
  color: $text-muted;
}

button,
.btn {
  cursor: pointer;
}

/* Wrapper */
.bsg-content-wrapper {
  display: flex;
  align-items: stretch;
}

/* Alert Wrapper */
#alert-wrapper {
  position: fixed;
  top: $header-height - 1px;
  width: calc(100% - #{$navigation-width});
  height: auto;
  margin-left: -15px;
  background-color: rgba(255, 255, 255, 0.8);
  border-bottom: 1px solid #ccc;
  box-shadow: 0 8px 8px -8px rgba(0, 0, 0, 0.3);
  transition: all 0.2s;
  z-index: 1000;
}

#alert-wrapper .alert {
  margin: 15px;
}

#alert-wrapper .alert:not(:last-child) {
  margin-bottom: 1rem !important;
}

/* Content */
#content {
  background-color: #fafcfd;
  width: 100%;
  min-height: 100vh;
  padding: $content-innenabstand;
  padding-top: $header-height;
  padding-bottom: $actionbar-container-height + 10px;
}

#actionpane {
  position: fixed;
  width: calc(100% - #{$navigation-width});
  bottom: 0;
  margin-left: -$content-innenabstand;
  margin-right: -$content-innenabstand;
  background-color: white;
  border-top: 1px solid $silver;
  transition: all 0.2s;
  z-index: 100;
}

ol.breadcrumb {
  border-radius: 0;
  box-shadow: inset 0 -1px 0 0 $silver;

  li.breadcrumb-item,
  li.breadcrumb-item a {
    color: $gunmetal;
    &.active {
      color: $black;
      font-weight: bolder;
      &:before {
        font-weight: normal;
        color: $gunmetal;
      }
    }
  }
}

/* Button White */
.btn-white,
.btn-white.disabled,
.btn-white:disabled {
  border-color: $input-border-color;
  border-radius: 0;
}

.btn-white:not([disabled]):not(.disabled):hover {
  border-color: $input-border-color;
  border-radius: 0;
}

.btn-white:not([disabled]):not(.disabled):active,
.show > .btn-white.dropdown-toggle {
  border-color: transparent;
  border-radius: 0;
}

.btn-white:focus,
.btn-white.focus {
  box-shadow: 0 0 0 0.2rem rgba(200, 200, 200, 0.5) !important;
  border-color: $input-border-color;
  border-radius: 0;
}

/* End Button White */

.btn-with-badge {
  position: relative;
}

.btn-with-badge .badge {
  position: absolute !important;
  top: -10px !important;
  right: -12px !important;
  color: $mkde-blue;
}

.btn-link {
  text-decoration: none;
  color: $mkde-blue;
}

.btn-outline-primary {
  color: $mkde-blue;
  border-color: $mkde-blue;
}

.btn-outline-primary:hover {
  background-color: $mkde-blue;
  border-color: $mkde-blue;
}

.input-group-text > .btn-outline-primary, .input-group-text > .btn-outline-primary > .btn:active {
  border-color: $input-group-addon-bg;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */
@media (max-width: 768px) {
  .bsg-content-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }

  #alert-wrapper {
    width: 100% !important;
  }

  #navigation {
    min-width: 100%;
    max-width: 100%;
    margin-left: 0 !important;
    padding-bottom: 0;
    border-right: none;
  }

  #functionbar,
  #actionpane {
    position: relative !important;
    width: calc(100% + 30px) !important;
  }

  #functionbar {
    top: 0 !important;
  }

  #content {
    padding-bottom: 15px !important;
  }

  body.close-navigation #navigation {
    display: none;
  }

  body:not(.close-navigation) {
    #content {
      padding-top: 0;
    }
  }
}
