.bsg-table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid $border-color;
  border-bottom: none;
  padding: 0.75rem;
}

.bsg-table {
  font-size: 14px;
  border: 1px solid $border-color;
  background-color: #fff;
  vertical-align: middle;

  a {
    text-decoration: none;
    background-color: transparent;
  }

  thead th {
    background-color: #f2f4f5;
    vertical-align: middle;
  }

  tr th a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $black;
  }

  tr th a:hover {
    text-decoration: none;
  }
}

.selected-row {
  color: #ffffff !important;
  background-color: $blue !important;
}
