input:required {
  box-shadow: none;
}

// BSG Label at Top
@mixin label-at-top {
  border-radius: $border-radius $border-radius 0 0;
  border-bottom-width: 0;
  border-right-width: 1px;
}

// BSG FormControl at Bottom
@mixin form-control-at-bottom {
  border-radius: 0 0 $border-radius $border-radius;
}

.bsg-form-group {
  line-height: 0;
  margin-bottom: 1rem;
}

.bsg-form-group-sm label,
.bsg-form-group label {
  line-height: 1.5;
}

.form-check-sm {
  max-height: 45px;
}

label.bsg-label {
  width: 100%;
  background-color: #f5f9fa;
  border: 1px solid $input-border-color;
  margin-bottom: 0;
  padding: 0.375rem 0.75rem;

  // Damit lange Texte abgeschnitten werden
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Label oben und Inputfield unten */
label.bsg-label {
  @include label-at-top;
}

.bsg-input-wrapper .form-control.bsg-form-control {
  @include form-control-at-bottom;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS */
    padding-bottom: 0.45rem;
    padding-top: 0.45rem;
  }
}

// Read Only
.form-control.bsg-form-control.is-read-only,
.form-control.bsg-form-control:disabled,
.form-control.bsg-form-control[readonly] {
  border-top-width: 0;
}

/*Inline Forms ohne Label*/

.input-group > .input-group-text {
  padding: 0 !important;
}

.form-check.input-group {
  width: 40%;
}

.form-group {
  margin-bottom: 1rem;
}

.form-inline .form-control {
  display: inline-block;
  width: auto;
  vertical-align: middle;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
}

.form-row > .col {
  padding-left: 5px;
  padding-right: 5px;
}

.form-row > .col,
.form-row > [class*='col-'] {
  padding-left: 5px;
  padding-right: 5px;
}

label {
  margin-bottom: 0.5rem;
}

.form-inline .form-control.bsg-form-control {
  border-radius: $border-radius;
}

/* Label links und Inpufield rechts */
.bsg-label-wrapper {
  padding-right: 0 !important;
}

.bsg-label-wrapper:not(.visually-hidden) + .bsg-input-wrapper {
  padding-left: 0;
}

.bsg-label-wrapper label.bsg-label {
  border-radius: $border-radius 0 0 $border-radius;
  border-right-width: 0;
  border-bottom-width: 1px;
}

.bsg-input-wrapper .form-control.bsg-form-control {
  border-radius: 0 $border-radius $border-radius 0;
}

/*
  Dieser Fall tritt ein wenn das bsg-label oben und das InputField unten haben will.
*/
:not(.bsg-label-wrapper, .visually-hidden)
  + :not(.bsg-input-wrapper)
  > .input-group
  > .form-control.bsg-form-control {
  @include form-control-at-bottom;
}

// ReadOnly
.bsg-input-wrapper .form-control.bsg-form-control.is-read-only,
.bsg-input-wrapper .form-control.bsg-form-control:disabled,
.bsg-input-wrapper .form-control.bsg-form-control[readonly] {
  border-left-width: 0;
  border-top-width: 1px;
}

/* Ende Label links und Inpufield rechts */

/** Allgemein **/

/* Input Icons */
.bsg-input-icon {
  position: absolute;
  top: 0.75rem;
  z-index: 3;
}

.bsg-input-icon.start-icon {
  left: 1rem;
}

.bsg-input-icon.end-icon {
  right: 1rem;
}

/* Validation */
label.is-invalid {
  color: $red;
}

.form-control.is-invalid {
  border-color: $red !important;
  z-index: 3;
}

.form-control.custom-checkbox {
  display: inline-block; // show validation tooltips centered over the label, not the screen
}

.invalid-icon {
  background-color: $red;
  color: white;
  border-color: $red;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  z-index: 3;
}

/* Read Only */
label.bsg-label.is-read-only {
  background-color: white;
}

.form-control.is-read-only,
.form-control:disabled,
.form-control[readonly] {
  background-color: white;
  font-weight: 600;
}

/* Sollte das Inputfield in der Inputgroup nicht das letzte Element sein so sind die Border nicht abgerundet */
.input-group > .form-control:not(:last-child),
.input-group > .form-select:not(:last-child),
.input-group > .form-control-component:not(:last-child) > .form-control {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

/** form-control-component **/
.input-group > .form-control-component {
  flex: 1 1 auto; // analog form-control
  width: 1%; // analog form-control (prevents wrapping the input-group-text onto the next line)
}

.input-group-text > .form-select {
  background-color: $input-group-addon-bg;
  border: 0;
}

ul.popover-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

@include media-breakpoint-down(sm) {
  /* Labels */
  .bsg-label-wrapper {
    padding-right: 5px !important;
  }

  .bsg-label-wrapper label.bsg-label {
    @include label-at-top;
  }

  .bsg-label-wrapper label.bsg-label .invalid-icon {
    right: 1rem;
  }

  /* Inputs */
  .bsg-input-wrapper:not(:first-child) {
    padding-left: 5px !important;
  }

  .bsg-input-wrapper .form-control.bsg-form-control {
    @include form-control-at-bottom;
  }

  .bsg-input-wrapper:not(:last-child) {
    margin-bottom: 1rem;
  }

  // ReadOnly
  .bsg-label-wrapper:not(.visually-hidden) + .bsg-input-wrapper .form-control.is-read-only,
  .bsg-label-wrapper:not(.visually-hidden) + .bsg-input-wrapper .form-control:disabled,
  .bsg-label-wrapper:not(.visually-hidden) + .bsg-input-wrapper .form-control[readonly] {
    border-top-width: 0;
    border-left-width: 1px;
  }
}
