/* You can add global styles to this file, and also import other style files */

$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
@import '@fortawesome/fontawesome-free/scss/regular';
@import '@fortawesome/fontawesome-free/scss/solid';
@import '@fortawesome/fontawesome-free/scss/fontawesome';
@import 'bootstrap/scss/bootstrap';
@import 'bootstrap';
@import 'custom';
@import './forms.scss';
@import './tables.scss';
@import './tables.scss';
@import './tabs.scss';
@import './modal.scss';
@import './bedag-style.scss';
@import './switch';
@import './checkbox';
@import './mkde-style';
