/* The custom checkbox */

.checkbox {
  display: block;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  min-width: 14px;
}

/* Hide the browser's default checkbox */

.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */

td .checkmark {
  top: -6px;
}

th .checkmark {
  top: -6px;
}

.checkmark {
  position: absolute;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #edecec;
  border-radius: 5px;
  box-shadow: inset 0 0 2px grey;
}

.checkmark-sm {
  position: absolute;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #edecec;
  border-radius: 5px;
  box-shadow: inset 0 0 2px grey;
}

.checkbox input:disabled ~ .checkmark,
.checkbox input:disabled ~ .checkmark-sm {
  background-color: #edecec;
  cursor: default;
}

/* On mouse-over, add a grey background color */

.checkbox:hover input ~ .checkmark,
.checkbox:hover input ~ .checkmark-sm,
.checkbox:hover input:checked ~ .checkmark,
.checkbox:hover input:checked ~ .checkmark-sm {
  background-color: #ccc;
}

.checkbox:hover input:disabled ~ .checkmark,
.checkbox:hover input:disabled ~ .checkmark-sm {
  background-color: #edecec;
  cursor: default;
}

/* When the checkbox is checked, add a blue background */

.checkbox input:checked ~ .checkmark,
.checkbox input:checked ~ .checkmark-sm {
  background-color: #edecec;
  border-radius: 5px;
  box-shadow: inset 0 0 2px grey;
}

/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after,
.checkmark-sm:after {
  content: '';
  position: absolute;
  display: none;
  box-sizing: unset;
}

/* Show the checkmark when checked */

.checkbox input:checked ~ .checkmark:after,
.checkbox input:checked ~ .checkmark-sm:after {
  display: block;
}

/* Style the checkmark/indicator */

.checkbox .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid #212529;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkbox .checkmark-sm:after {
  left: 8px;
  top: 5px;
  width: 3px;
  height: 6px;
  border: solid #212529;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkbox-label {
  font-size: initial;
  padding-left: 2rem;
  vertical-align: top;
}

.form-check {
  padding-right: 1rem;
  padding-left: 0;
}

.form-check-indented {
  padding-left: 1rem;
}
