.nav-tabs .nav-link {
  font-weight: 600;
  color: $blue;
  border-top: 0;
  border-right: 0;
  border-left: 0;
}

.nav-tabs {
  border-bottom: 1px solid $silver;
}

.nav-tabs .nav-link.active {
  color: black;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  background-color: transparent !important;
  border-color: black !important;
}
