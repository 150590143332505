html {
  font-size: 16px;
}

#header {
  height: 50px;
  padding: 0 1rem;
  .navbar-brand {
    font-size: 22px;
    font-weight: 600;
    margin-left: 1rem;
    margin-right: 0px;
  }
  .applogo {
    width: 30px;
    margin-right: 0.25rem !important;
    margin-top: -5px;
  }
  .username {
    position: relative;
    bottom: 0px;
  }
}

.navbar-mkde {
  position: fixed;
  left: 0;
  top: 50px;
  width: 100%;
  z-index: 1000;
  padding: 0;
  background-color: shade-color($table-head-bg, 5);
  padding-left: 0;
  margin-left: 0;

  .navbar-nav {
    flex-direction: row;
    flex-wrap: nowrap;

    li {
      width: 250px;
      color: $mkde-blue;
      text-decoration: none;
    }

    li.active {
      background-color: #fafcfd;

      .btn-nav {
        color: initial;
        font-weight: bold;
      }
    }

    .btn-nav {
      width: 100%;
      text-align: center;
    }

    .btn-nav:hover {
      background-color: shade-color($table-head-bg, 10);
      text-decoration: none;
    }

    .btn-nav.disabled,
    .btn-nav:disabled {
      opacity: 0.85;
    }
  }
}

#functionbar,
#actionpane {
  width: 100% !important;
}

#actionpane {
  justify-content: flex-start !important;
  position: fixed;
  bottom: 0;
  margin-left: -$content-innenabstand;
  margin-right: -$content-innenabstand;
  background-color: white;
  border-top: 1px solid $silver;
  transition: all 0.2s;
  z-index: 100;
}

#alert-wrapper {
  width: 35% !important;
  right: 0;
  top: 83px;
  border: none;
  .alert {
    padding-bottom: 25px;
  }
}

#content {
  padding-left: 1rem;
  padding-right: 1rem;
}

.table-striped {
  --bs-table-striped-color: --bs-table-color;
  --bs-table-striped-bg: --bs-table-bg;

  tbody tr th, tbody tr td, thead tr th {
    padding: 0.2rem;
  }

  tbody tr:hover:not(.selected-row) td, tbody tr:hover:not(.selected-row) th {
    background-color: $lightblue;
  }

  tbody tr:hover.selected-row td, tbody tr:hover.selected-row th {
    color: white;
  }

  tbody tr:nth-of-type(#{$table-striped-order}):not(:hover) {
    background-color: shade-color($table-accent-bg, 5);
  }
}

.highlightedRow {
  background-color: #ffdede !important;
}

.disabled-row {
  color: #9f9f9f;
}

.selectable-row {
  cursor: pointer;
}

.selected-row {
  background-color: $mkde-blue !important;
}

.fw-bold {
  font-weight: 600 !important;
}

.underlined {
  border-bottom: 1px solid $black;
  padding-bottom: 0.25rem;
}

.td-button {
  padding: 0 !important;
  vertical-align: middle !important;
}

.page-header {
  h1 {
    font-size: 1.5rem;
    font-weight: 500;
  }
  padding-left: 0.2rem;
  padding-right: 1rem;
  margin-bottom: 0.5rem;
  margin-top: 45px;
  display: flex;
  flex-wrap: wrap;
}

.page-content {
  margin-top: 65px;
}

.bsg-table-header h3 {
  font-size: 1.6rem;
}

.btn-dialog {
  width: 50%;
}

.btn-dialog-sm {
  width: 15%;
}

.btn-dialog-md {
  width: 25%;
}

.btn-back {
  padding: 6px 0px;
  margin-right: 0.5rem;
}

.btn-circle {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  text-align: center;
  font-size: 16px;
  line-height: 1.42857;
}

.align-right {
  margin-left: auto;
}

.clickable {
  cursor: pointer;
}

// Needs to be here, as a variable is referenced
.description-icon {
  color: $primary;
  float: right;
  top: 5px;
  right: 5px;
}

.modal-content {
  border: none;
}

.detail-list-value {
  .bsg-form-group {
    margin-bottom: 0;
  }
}

.detail-result-number-field-value {
  .bsg-form-group {
    margin-bottom: 0;
  }
}

.form-control.is-read-only,
.form-control:disabled,
.form-control[readonly] {
  font-weight: initial;
}

button[disabled] {
  cursor: default;
}

.fa-1-5x {
  font-size: 1.5rem;
}

.btn-details-toggle {
  padding: 0;
}

.btn-details-toggle:hover,
.btn-details-toggle:focus {
  text-decoration: none;
}

.btn-with-badge .badge-lg {
  font-size: 100%;
  right: -15px !important;
  color: white;
}

input[type='number'],
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  text-align: right;
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield;
}

.pull-left {
  margin-right: auto !important;
}

.form-control.bsg-form-control:disabled[ngbdatepicker] {
  border-left-width: 1px;
}

.card-header {
  background-color: shade-color($table-accent-bg, 5);
}

.multiline {
  white-space: pre-wrap;
}

.popover-left-border ~ .bs-popover-bottom,
.popover-left-border ~ .bs-popover-top {
  left: 5px !important;
}

.dropdown-menu {
  max-height: 540px;
  overflow-y: auto;
}
