$mkde-blue: #5499ff;
$primary: $mkde-blue;

$header-height: 50px;
$navigation-width: 300px;
$function-bar-height: 40px;
$actionbar-container-height: 48px;
$content-innenabstand: 15px;

$white: #fff;
$silver: #d3d9dc;
$cool-grey: #8c969c;
$gunmetal: #4e5d63;
$black: #000;
$blue: #0070e8;
$red: #dc3545;
$yellow: #ffc107;
$green: #218838;
$lightblue: #edf5fc;

$theme-colors: (
  'white': $white,
);

$table-bg: $white;
$table-border-color: $silver;
$table-hover-bg: rgba(0, 120, 215, 0.07);
$table-head-bg: #f2f4f5;
$table-accent-bg: #f8f9fa;
$table-border-color: $silver;

$tooltip-bg: $black;
$tooltip-opacity: 1;
$tooltip-arrow-width: 0.6rem;
$tooltip-arrow-height: 0.3rem;
$popover-arrow-width: 0.6rem;
$popover-arrow-height: 0.3rem;

$breadcrumb-bg: transparent;
$breadcrumb-active-color: $black;
$breadcrumb-padding-y: 0.3rem;
$breadcrumb-divider: '❭';

$modal-content-bg: #2d3438;
$modal-content-border-color: #2d3438;
$modal-lg: 1100px !default;
$modal-md: 800px !default;
$modal-sm: 450px;

$enable-validation-icons: false !default;
