.modal-body {
  background-color: white;
}

.modal-header {
  color: white;
}

.modal-header .btn-close {
  color: white;
  opacity: 0.75;
}

.modal-header .btn-close:hover {
  color: white !important;
  opacity: 1 !important;
}

.modal-footer {
  background-color: white;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.modal-dialog-md .modal-dialog {
  min-width: 33% !important;
}
